<template>
    <zj-scroll-list :dataList="earlyWarningList" :delay="1200" :limit="4" ></zj-scroll-list>
</template>

<script>
import ajax from '@/ajax.js'
import ZjScrollList from 'zj-scroll-list'
export default {
    name: 'ComEarlyWarnings',
    components: { 
        ZjScrollList,
    },
    data(){
        return {
            earlyWarnings: [],
        }
    },
	computed:{
		earlyWarningList(){
            let list = [];
            list = this.earlyWarnings.map( it => {
                // console.log("earlyWarningList 1:", it)
            	return {
            		"key": "key1",
            		"row": [
            		    {
            		        // "icon": require("@/static/icons/icon-warning.svg"),
            		        // "icon_color": "green",
                            flex: 5,
            		        "text": it.name,
            				"tip": it.name,
            		    },
            		    {
                            flex: 3,
            		        "text": it.status,
            		        "text_color": it.status === '正常' ? "green" :　it.status === '预警' ? 'yellow' : it.status === '报警' ? 'red' : "gray",
            		    },
            		    {
                            flex: 5,
            		        "text": it.time,
            		    },
            		]
            	}
            });
            // console.log("earlyWarningList 2:", list)
			return list;
		},
	},
	created(){
		this.download();
	},
	methods:{
		download(){
		    ajax({url: '/api/early_warnings',}).then(res => {
		        // console.log("download:", res);
		        this.earlyWarnings = res;
		    })
		},
	},
	watch:{
		earlyWarnings(val, old){
			// console.log("watch: earlyWarnings:", val)
			// this.update();
		}
	},
	
}
</script>